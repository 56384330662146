<template>
    <!-- <PartnerHeader /> -->
    <UHeader title="NomadRx" to="/dashboard">
        <template #logo>
            <p class="tracking-tight">NomadRx</p>
        </template>
        <template #right>
            <div class="hidden lg:flex items-center gap-1.5">
                <UDropdown :items="accountItems">
                    <UButton color="gray" variant="ghost" square icon="i-heroicons-user-solid" />
                </UDropdown>
            </div>
        </template>
        <template #panel>
            <UHeaderPopoverLinks :links="links" />
            <UDivider class="my-4" />
            <div class="mt-4 space-y-2">
                <UButton label="Sign out" icon="i-heroicons-arrow-left-end-on-rectangle" @click="signOutUser" block
                    variant="soft" size="xl" />
            </div>
        </template>
    </UHeader>
    <UMain>
        <UContainer>
            <UPage>
                <template #left>
                    <UAside>
                        <UAsideLinks :links="links" class="pt-0" :ui="{ wrapper: 'space-y-4' }" />
                    </UAside>
                </template>
                <div class="mb-20">
                    <slot />
                </div>
            </UPage>
        </UContainer>
    </UMain>
</template>


<script setup lang="ts">
const { signOutUser } = useFirebaseAuth();

const accountItems = [
    [{
        label: 'Settings',
        icon: 'i-heroicons-cog-8-tooth',
        to: "/dashboard/settings"
    }], [{
        label: 'Sign out',
        icon: 'i-heroicons-arrow-left-end-on-rectangle',
        click: async () => {
            await signOutUser();
        }
    }]
]

const links = [
    {
        label: 'Home',
        to: '/dashboard',
        // icon: 'i-heroicons-home',
        exact: true,
    },
    {
        label: 'Claims',
        to: '/dashboard/claims',
        // icon: 'i-heroicons-user-group',
    },
    {
        label: 'Payouts',
        to: '/dashboard/payouts',
        // icon: 'i-heroicons-banknotes',
    },
    {
        label: 'Account',
        to: '/dashboard/settings',
        // icon: 'i-heroicons-banknotes',
    }
]

</script>
